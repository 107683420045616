interface ConfigInterface {
  comingSoonMode: boolean;
  comingSoonEndDate: string;
}

export const config: ConfigInterface = {
  comingSoonMode: false,
  // ignore this date - hotfix
  comingSoonEndDate: '11/30/2022 18:00 UTC'
};

interface SocialsConfigInterface {
  email: string;
  twitterUrl: string;
  discordUrl: string;
  youtubeUrl: string;
  redditUrl: string;
  mediumUrl: string;
  instagramUrl: string;
  tiktokUrl: string;
  facebookUrl: string;
  telegramUrl: string;
  lineUrl: string;
}

export const socialsConfig: SocialsConfigInterface = {
  email: 'info@paimastudios.com',
  twitterUrl: 'https://twitter.com/PaimaStudios',
  discordUrl: 'https://discord.com/invite/jZ59ArVaxv',
  youtubeUrl: 'https://www.youtube.com/channel/UCiQQPg--xxGnFXDfMmot4AA',
  redditUrl: 'https://www.reddit.com/r/paima/',
  mediumUrl: 'https://medium.com/@Paima_Studios',
  instagramUrl: 'https://www.instagram.com/paimastudios/',
  tiktokUrl: 'https://www.tiktok.com/@paimastudios',
  facebookUrl: 'https://www.facebook.com/profile.php?id=100085835360593',
  telegramUrl: 'https://t.me/PaimaStudios_Official',
  lineUrl: 'https://line.me/ti/g2/Cn6O4s1jhDpktd9UPh9AQTcDcGlr64QVnSGHjA'
};
