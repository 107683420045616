import classNames from 'classnames';
import React, { createContext, useEffect } from 'react';

import { config } from '../../config';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import * as Analytics from '../../services/analytics-utils';

interface LayoutProps {
  title: string;
  children: React.ReactNode | React.ReactFragment | React.ReactElement;
  mainWrapperClass?: string;
}

export interface LayoutContextProps {
  comingSoonMode: boolean;
  comingSoonEndDate: string;
}

export const LayoutContext = createContext<LayoutContextProps>({
  comingSoonMode: config.comingSoonMode,
  comingSoonEndDate: config.comingSoonEndDate
});

const Layout = ({ title, children, mainWrapperClass }: LayoutProps) => {
  useEffect(() => {
    Analytics.pageview(Analytics.getPathname(), title);
  }, []);

  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata();

  const meta = {
    title: defaultTitle,
    description: defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}`,
    type: 'website'
  };

  return (
    <main className={classNames(mainWrapperClass ?? '')}>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="image" content={meta.image} />
      <meta property="og:type" content={meta.type} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@PaimaStudios" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <LayoutContext.Provider
        value={{ comingSoonEndDate: config.comingSoonEndDate, comingSoonMode: config.comingSoonMode }}
      >
        {children}
      </LayoutContext.Provider>
    </main>
  );
};

export default Layout;
